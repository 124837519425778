<template>
  <form-section
    v-if="businessSectionComplete"
    :id="sectionId"
    class="your-offer"
    @visibility="onChangeSectionVisibility"
  >
    <form-group
      :is-visible="currentQuestion === offerBannerId"
      @visibility="
        isVisible => onChangeQuestionVisibility(isVisible, offerBannerId)
      "
    >
      <!-- Section intro -->
      <mobile-full-page-wrapper :go-to-id="offerBannerId" show-arrow>
        <v-layout :align-center="$vuetify.breakpoint.xsOnly">
          <v-flex xs12 md9>
            <typography type="h3"
              >We've got this awesome offer for you...</typography
            >
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>

      <mobile-full-page-wrapper :id="offerBannerId">
        <!-- Fuel offer banner -->
        <v-layout
          v-if="isDiscountOffPump"
          :justify-center="$vuetify.breakpoint.xsOnly"
          class="no-grow"
        >
          <v-flex xs12 md9>
            <fuel-offer-carousel  :value="offerDiscounts" />
          </v-flex>
        </v-layout>

        <v-layout
          v-if="!isDiscountOffPump"
          :justify-center="$vuetify.breakpoint.xsOnly"
          class="no-grow no-discount-off-pump"
        >
          <v-flex xs12 md9>
            <rich-text-viewer v-if="benefits" :value="benefits" />
          </v-flex>
        </v-layout>
          <v-flex mb-1>
            <typography type="small-caption-text bold" style="color:#ff6f2a">
              Your Z Business account also has no account, card or transaction fees.<br /><br />
            </typography>
          </v-flex>
        <!-- Promo code -->
        <v-layout
          :id="promoCodeId"
          :justify-center="$vuetify.breakpoint.xsOnly"
          class="no-grow"
        >
          <v-flex>
            <!-- Promo code title -->
            <v-layout mb-4>
              <v-flex>
                <typography type="h5">Got a promo code handy?</typography>
              </v-flex>
            </v-layout>

            <!-- Promo code text field -->
            <v-layout mb-4>
              <v-flex md7 lg5>
                <text-input-with-button
                  :value="promoCode"
                  :error-messages="promoCodeErrors"
                  :loading="$v.promoCode.$pending"
                  placeholder="Enter promo code"
                  button-text="Confirm"
                  name="PromoCode"
                  @input="onChangePromoCode"
                  @click="onApplyPromoCode"
                />
              </v-flex>
            </v-layout>

            <!-- Membership number title -->
            <v-layout
              v-if="membershipNumberRequired"
              :id="membershipNumberId"
              mb-4
            >
              <v-flex>
                <typography type="h5" v-if="!isTrustOrSociety">
                  We'll need your membership number for this promo
                </typography>
                <typography type="h5" v-else>
                  We'll need your Charities number for this promo
                </typography>
              </v-flex>
            </v-layout>

            <!-- Membership number text field -->
            <v-layout v-if="membershipNumberRequired" mb-4>
              <v-flex md7 lg5>
                <text-input
                  v-model="membershipNumber"
                  :rules="[
                    () =>
                      $v.membershipNumber.required || 'This field is required',
                    () =>
                      $v.membershipNumber.maxLength || 'This field is invalid'
                  ]"
                  :label="membershipNumberLabel"
                  name="MembershipNumber"
                  no-margin
                  @blur="$v.membershipNumber.$touch()"
                  :maxlength="20"
                />
              </v-flex>
            </v-layout>

            <!-- Promo code complete button -->
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs10 sm6 md12>
                <round-button
                  name="promoCodeCompleteButton"
                  v-if="!promoCodeComplete"
                  :disabled="$v.$invalid"
                  :outline="earnsLoyaltyPoints"
                  @click="onCompletePromoCodeClick"
                  >{{ promoCodeButtonText }}</round-button
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
    </form-group>

    <form-group
      v-if="promoCodeComplete && earnsLoyaltyPoints"
      :id="loyaltyId"
      :is-visible="currentQuestion === loyaltyId"
      @visibility="
        isVisible => onChangeQuestionVisibility(isVisible, loyaltyId)
      "
    >
      <!-- Point type -->
      <mobile-full-page-wrapper>
        <v-layout :justify-center="$vuetify.breakpoint.xsOnly" align-center>
          <v-flex xs12 sm12 md9>
            <!-- Point type heading -->
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly" mb-4>
              <v-flex xs10>
                <typography type="h5"
                  >Could we sweeten the deal with...</typography
                >
                <typography
                  :type="
                    $vuetify.breakpoint.xsOnly
                      ? 'small-p'
                      : 'small-caption-text'
                  "
                  light
                >This is optional, you can do this later if you don’t have the details on hand.
                </typography>
              </v-flex>
            </v-layout>

            <!-- Point type buttons -->
            <toggle-button-wrapper>
              <toggle-button
                :active="pointType === airpointsValue"
                :value="airpointsValue"
                has-suboption
                name="AirpointsButton"
                @click="onPointTypeChange"
              >
                <airpoints-icon
                  v-if="
                    pointType !== airpointsValue && $vuetify.breakpoint.mdAndUp
                  "
                />
                <airpoints-white-icon
                  v-else-if="
                    pointType === airpointsValue && $vuetify.breakpoint.mdAndUp
                  "
                />
                <airpoints-mobile-icon
                  v-else-if="
                    pointType !== airpointsValue &&
                      $vuetify.breakpoint.smAndDown
                  "
                />
                <airpoints-mobile-white-icon
                  v-else-if="
                    pointType === airpointsValue &&
                      $vuetify.breakpoint.smAndDown
                  "
                />
              </toggle-button>
              <toggle-button
                :active="pointType === noPointTypeValue"
                :value="noPointTypeValue"
                label="No thanks"
                name="NoPointTypeButton"
                @click="onPointTypeChange"
              />
            </toggle-button-wrapper>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>

      <!-- Airpoints -->
      <mobile-full-page-wrapper
        v-if="pointType === airpointsValue"
        :id="airpointsId"
      >
        <v-layout fill-height column justify-space-between>
          <!-- Airpoints titles -->
          <v-flex mb-4>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex>
                <typography v-if="$vuetify.breakpoint.xsOnly" type="h5"
                  >What is your Airpoints&trade; number?</typography
                >
                <typography
                  :type="
                    $vuetify.breakpoint.xsOnly
                      ? 'small-p'
                      : 'small-caption-text'
                  "
                  light
                >
                  You can only register one Airpoints&trade; number per {{ BRAND }} account.<br />
                  Please provide Airpoints&trade; number, first name and last name as per your Airpoints&trade; account.
                  <br>Airpoints&trade; for Business Accounts are not eligible.
                </typography>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Airpoints text fields -->
          <v-flex mb-4>
            <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
              <v-flex xs11 sm10 md6>
                <text-input
                  key="airpointsNumber"
                  :value="airpointsNumber"
                  :error-messages="airpointsNumberErrors"
                  label="Airpoints™ number"
                  name="AirpointsNumber"
                  mask="########"
                  pattern="\d*"
                  @input="onChangeUnvalidatedAirpointsNumber"
                  @blur="$v.unvalidatedAirpointsNumber.$touch()"
                />
                <text-input
                  :value="airpointsFirstName"
                  :maxlength="50"
                  :error-messages="airpointsFirstNameErrors"
                  label="First name"
                  name="AirpointsFirstName"
                  @input="onChangeUnvalidatedAirpointsFirstName"
                  @blur="$v.unvalidatedAirpointsFirstName.$touch()"
                />
                <text-input
                  :value="airpointsLastName"
                  :maxlength="50"
                  :error-messages="airpointsLastNameErrors"
                  label="Last name"
                  name="AirpointsLastName"
                  @input="onChangeUnvalidatedAirpointsLastName"
                  @blur="$v.unvalidatedAirpointsLastName.$touch()"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </mobile-full-page-wrapper>
      <!-- Next section button for desktop -->
      <v-flex
        class="no-grow"
        v-if="!offerSectionComplete || pointType !== noPointTypeValue"
      >
        <v-layout :justify-center="$vuetify.breakpoint.xsOnly">
          <v-flex xs10 sm6 md12>
            <round-button
              :outline="loyaltyButtonHasOutline"
              @click="loyaltyButtonClicked"
              :loading="isLoading"
              :disabled="isDisabled"
              >{{ loyaltyButtonText }}</round-button
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </form-group>
    <promo-offer-dialog />
  </form-section>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import FuelOfferCarousel from '@/components/molecules/FuelOfferCarousel.vue';
import FormGroup from '@/components/atoms/FormGroup.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import ToggleButtonWrapper from '@/components/atoms/ToggleButtonWrapper.vue';
import ToggleButton from '@/components/molecules/ToggleButton.vue';
import TextInputWithButton from '@/components/molecules/TextInputWithButton.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import FormSection from '@/components/atoms/FormSection.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import AirpointsIcon from '@/assets/icons/airpoints.svg';
import AirpointsWhiteIcon from '@/assets/icons/airpoints-white.svg';
import AirpointsMobileIcon from '@/assets/icons/airpoints-mobile.svg';
import AirpointsMobileWhiteIcon from '@/assets/icons/airpoints-mobile-white.svg';
import PromoOfferDialog from '@/components/organisms/PromoOfferDialog.vue';
import {
  SECTION_YOUR_OFFER,
  SECTION_CREDIT_CHECK,
  LOYALTY,
  PROMO_CODE,
  OFFER_BANNER,
  MEMBERSHIP_NUMBER,
  FLY_BUYS_ID,
  AIRPOINTS_ID
} from '@/constants/html-ids';
import { POINTS_NONE, AIRPOINTS, BRAND, TRUST_OR_SOCIETY } from '@/constants/form';
import { mapState, mapGetters } from 'vuex';
import { requiredIf, minLength, maxLength } from 'vuelidate/lib/validators';
import { nullOrWhitespace } from '@/helpers/string';
import api from '@/api';
import RichTextViewer from '@/components/atoms/RichTextViewer';
import { navigationOffset } from '@/helpers/navigation';

export default {
  components: {
    Typography,
    FuelOfferCarousel,
    FormGroup,
    AirpointsMobileIcon,
    AirpointsMobileWhiteIcon,
    MobileFullPageWrapper,
    TextInput,
    TextInputWithButton,
    ToggleButtonWrapper,
    ToggleButton,
    RoundButton,
    FormSection,
    AirpointsIcon,
    AirpointsWhiteIcon,
    PromoOfferDialog,
    RichTextViewer
  },
  data() {
    return {
      BRAND: BRAND,
      unvalidatedAirpointsNumber: this.$store.state.offer.airpointsNumber,
      unvalidatedAirpointsFirstName: this.$store.state.offer.airpointsFirstName,
      unvalidatedAirpointsLastName: this.$store.state.offer.airpointsLastName,
    };
  },
  computed: {
    isLoading: {
      get() {

        if (this.pointType === AIRPOINTS) {
          return this.$v.airpoints.$pending;
        }

        return false;
      }
    },
    isDisabled: {
      get() {
        if (!this.pointType) {
          return true;
        }

        if (this.pointType === AIRPOINTS) {
          if (
            !this.$v.unvalidatedAirpointsNumber.required ||
            !this.$v.unvalidatedAirpointsFirstName.required ||
            !this.$v.unvalidatedAirpointsLastName.required
          ) {
            return true;
          }
        }

        return false;
      }
    },
    loyaltyButtonText() {
      if (this.offerSectionComplete && this.pointType !== POINTS_NONE) {
        return 'Confirm';
      }

      return 'Next: Credit check details';
    },
    loyaltyButtonHasOutline() {
      return this.loyaltyButtonText === 'Confirm';
    },
    promoCodeButtonText() {
      if (this.promoCode) {
        return this.earnsLoyaltyPoints ? 'Next' : 'Next: Credit check details';
      }

      return 'Skip';
    },
    promoCode: {
      get() {
        return this.$store.state.offer.promoCode;
      },
      set(value) {
        this.$store.dispatch('offer/changePromoCode', value);
      }
    },
    isDiscountOffPump() {
      return this.$store.state.offer.isDiscountOffPump;
    },
    membershipNumber: {
      get() {
        return this.$store.state.offer.membershipNumber;
      },
      set(value) {
        this.$store.dispatch('offer/changeMembershipNumber', value);
      }
    },
    airpointsNumber: {
      get() {
        return this.$store.state.offer.airpointsNumber;
      },
      set(value) {
        this.$store.dispatch('offer/changeAirpointsNumber', value);
      }
    },
    airpointsFirstName: {
      get() {
        return this.$store.state.offer.airpointsFirstName;
      },
      set(value) {
        this.$store.dispatch('offer/changeAirpointsFirstName', value);
      }
    },
    airpointsLastName: {
      get() {
        return this.$store.state.offer.airpointsLastName;
      },
      set(value) {
        this.$store.dispatch('offer/changeAirpointsLastName', value);
      }
    },
    isTrustOrSociety: {
      get() {
        return this.$store.state.business.businessType === TRUST_OR_SOCIETY
      }
    },
    membershipNumberLabel: {
      get() {
        if (this.isTrustOrSociety) {
          return "Registration Number";
        }

        return "Membership Number";
      }
    },
    promoCodeErrors() {
      let errors = [];

      if (!this.$v.promoCode.$dirty || this.$v.promoCode.$pending) {
        return [];
      }

      if (!this.$v.promoCode.isValid) {
        errors = [...errors, 'Promo code not valid'];
      }

      return errors;
    },
    airpointsNumberErrors() {
      let errors = [];

      if (
        !this.$v.unvalidatedAirpointsNumber.$dirty ||
        this.$v.airpoints.$pending
      ) {
        return [];
      }

      if (!this.$v.unvalidatedAirpointsNumber.required) {
        return [...errors, 'This field is required'];
      }

      if (!this.$v.airpoints.$dirty) {
        return [];
      }

      if (!this.$v.airpoints.isValid) {
        errors = [...errors, 'Airpoint details not valid'];
      }

      return errors;
    },
    airpointsFirstNameErrors() {
      let errors = [];

      if (
        !this.$v.unvalidatedAirpointsFirstName.$dirty ||
        this.$v.airpoints.$pending
      ) {
        return [];
      }

      if (!this.$v.unvalidatedAirpointsFirstName.required) {
        return [...errors, 'This field is required'];
      }

      if (!this.$v.airpoints.$dirty) {
        return [];
      }

      if (!this.$v.airpoints.isValid) {
        errors = [...errors, 'Airpoint details not valid'];
      }

      return errors;
    },
    airpointsLastNameErrors() {
      let errors = [];

      if (
        !this.$v.unvalidatedAirpointsLastName.$dirty ||
        this.$v.airpoints.$pending
      ) {
        return [];
      }

      if (!this.$v.unvalidatedAirpointsLastName.required) {
        return [...errors, 'This field is required'];
      }

      if (!this.$v.airpoints.$dirty) {
        return [];
      }

      if (!this.$v.airpoints.isValid) {
        errors = [...errors, 'Airpoint details not valid'];
      }

      return errors;
    },
    benefits: function() {
      return (
        this.$store.state.offer.promoOffer &&
        this.$store.state.offer.promoOffer.benefits
      );
    },
    sectionId: () => SECTION_YOUR_OFFER,
    noPointTypeValue: () => POINTS_NONE,
    airpointsValue: () => AIRPOINTS,
    loyaltyId: () => LOYALTY,
    promoCodeId: () => PROMO_CODE,
    membershipNumberId: () => MEMBERSHIP_NUMBER,
    offerBannerId: () => OFFER_BANNER,
    airpointsId: () => AIRPOINTS_ID,
    ...mapState({
      businessSectionComplete: state => state.business.sectionComplete,
      businessType: state => state.business.businessType,
      pointType: state => state.offer.pointType,
      promoCodeComplete: state => state.offer.promoCodeComplete,
      offerSectionComplete: state => state.offer.sectionComplete
    }),
    ...mapGetters({
      currentQuestion: 'navigation/currentQuestion',
      offerDetails: 'offer/offerDetails',
      offerDiscounts: 'offer/offerDiscounts',
      earnsLoyaltyPoints: 'offer/earnsLoyaltyPoints',
      membershipNumberRequired: 'offer/membershipNumberRequired'
    })
  },
  methods: {
    async onCompletePromoCodeClick() {
      this.$store.dispatch('offer/changePromoCodeComplete', true);

      // Skip loyalty if a promo code was entered that doesn't include loyalty
      if (!this.earnsLoyaltyPoints) {
        this.$store.dispatch('offer/changePointType', POINTS_NONE);
        this.$store.dispatch('offer/changeSectionComplete', true);
      }

      await this.$nextTick();

      if (this.offerSectionComplete) {
        this.$vuetify.goTo(`#${SECTION_CREDIT_CHECK}`, {
          offset: navigationOffset(SECTION_CREDIT_CHECK)
        });
      } else {
        this.$vuetify.goTo(`#${LOYALTY}`);
      }
    },
    async onChangePromoCode(value) {
      // Update model and revalidate when the user clears the field
      if (value === '') {
        this.$store.dispatch('offer/changePromoOffer', null);
        this.$store.dispatch('offer/changePromoCode', value);
        this.$store.dispatch('offer/changePromoCodeValid', true);

        await this.$nextTick();
      }
    },
    onApplyPromoCode(value) {
      if (!nullOrWhitespace(value)) {
        this.$store.dispatch('offer/getOfferByPromoCode', {
          promoCode: value
        });

        this.$v.promoCode.$touch();
      }
    },
    async onPointTypeChange(value) {
      this.$store.dispatch('offer/changePointType', value);

      // AO-658: Clear out the loyalty details if the section has previously
      // been completed and they opt out of loyalty programmes.
      if (value === POINTS_NONE && this.offerSectionComplete) {

        this.resetAirpoints();
      }

      if (this.$vuetify.breakpoint.smAndDown) {
        await this.$nextTick();

        switch (value) {
          case AIRPOINTS:
            this.$vuetify.goTo(`#${AIRPOINTS_ID}`);
            break;
          case POINTS_NONE:
            this.onNextSection();
            break;
          default:
            break;
        }
      }
    },
    resetAirpoints() {
      this.$v.unvalidatedAirpointsNumber.$reset();
      this.$v.unvalidatedAirpointsFirstName.$reset();
      this.$v.unvalidatedAirpointsLastName.$reset();
      this.$store.dispatch('offer/changeAirpointsNumber', '');
      this.$store.dispatch('offer/changeAirpointsLastName', '');
      this.$store.dispatch('offer/changeAirpointsFirstName', '');
      this.unvalidatedAirpointsNumber = '';
      this.unvalidatedAirpointsFirstName = '';
      this.unvalidatedAirpointsLastName = '';
    },
    loyaltyButtonClicked() {

      if (this.pointType === AIRPOINTS) {
        this.$v.airpoints.$reset();
        // Sync airpoints values and revalidate through api
        // Set to empty first so validation can retrigger on same value
        this.$store.dispatch('offer/changeAirpointsNumber', '');
        this.$store.dispatch('offer/changeAirpointsFirstName', '');
        this.$store.dispatch('offer/changeAirpointsLastName', '');
        this.$store.dispatch(
          'offer/changeAirpointsNumber',
          this.unvalidatedAirpointsNumber
        );
        this.$store.dispatch(
          'offer/changeAirpointsFirstName',
          this.unvalidatedAirpointsFirstName
        );
        this.$store.dispatch(
          'offer/changeAirpointsLastName',
          this.unvalidatedAirpointsLastName
        );

        this.$v.airpoints.$touch();
      }

      if (this.pointType === POINTS_NONE) {

        this.resetAirpoints();
        this.onNextSection();
      }
    },
    onAirpointsNumberBlur(value) {
      this.$v.airpointsNumber.$touch();
      this.$store.dispatch('offer/changeAirpointsNumber', value);
    },
    onChangeUnvalidatedAirpointsNumber(value) {
      this.$v.unvalidatedAirpointsNumber.$touch();
      this.unvalidatedAirpointsNumber = value;
    },
    onChangeUnvalidatedAirpointsFirstName(value) {
      this.$v.unvalidatedAirpointsFirstName.$touch();
      this.unvalidatedAirpointsFirstName = value;
    },
    onChangeUnvalidatedAirpointsLastName(value) {
      this.$v.unvalidatedAirpointsLastName.$touch();
      this.unvalidatedAirpointsLastName = value;
    },
    async onNextSection() {
      this.$store.dispatch('offer/changeSectionComplete', true);

      await this.$nextTick();

      this.$vuetify.goTo(`#${SECTION_CREDIT_CHECK}`, {
        offset: navigationOffset(SECTION_CREDIT_CHECK)
      });
    },
    onChangeSectionVisibility(isVisible) {
      if (isVisible) {
        this.$store.dispatch('navigation/addSectionInView', SECTION_YOUR_OFFER);
      } else {
        this.$store.dispatch(
          'navigation/removeSectionInView',
          SECTION_YOUR_OFFER
        );
      }
    },
    onChangeQuestionVisibility(isVisible, htmlId) {
      if (isVisible) {
        this.$store.dispatch('navigation/addQuestionInView', htmlId);
      } else {
        this.$store.dispatch('navigation/removeQuestionInView', htmlId);
      }
    }
  },
  validations: {
    pointType: {
      required: requiredIf(nestedModel => nestedModel.promoCodeComplete)
    },
    unvalidatedAirpointsNumber: {
      required: requiredIf(nestedModel => nestedModel.pointType === AIRPOINTS)
    },
    unvalidatedAirpointsFirstName: {
      required: requiredIf(nestedModel => nestedModel.pointType === AIRPOINTS)
    },
    unvalidatedAirpointsLastName: {
      required: requiredIf(nestedModel => nestedModel.pointType === AIRPOINTS)
    },
    airpointsNumber: {
      required: requiredIf(nestedModel => nestedModel.pointType === AIRPOINTS)
    },
    airpointsFirstName: {
      required: requiredIf(nestedModel => nestedModel.pointType === AIRPOINTS)
    },
    airpointsLastName: {
      required: requiredIf(nestedModel => nestedModel.pointType === AIRPOINTS)
    },
    airpoints: {
      async isValid() {
        if (!this.promoCodeComplete) {
          return true;
        }

        if (
          !this.airpointsNumber &&
          !this.airpointsFirstName &&
          !this.airpointsLastName
        ) {
          return true;
        }

        var isValid = true;
        var isValidated = true;

        try {
          var response = await api.validateAirpoints(
            this.airpointsNumber,
            this.airpointsFirstName,
            this.airpointsLastName
          );

          // 200 == valid, 204 == invalid
          isValid = response.status == 200;
        } catch (error) {
          // If the api is down (ours or Air NZ), let them enter their details but mark the
          // details as not validated.
          if (!error.response || error.response.status == 500) {
            isValidated = false;
          }
          // Any other status codes should be treated as invalid details.
          else {
            isValid = false;
          }
        }

        this.$store.dispatch('offer/changeAirpointsValid', isValid);
        this.$store.dispatch('offer/changeAirpointsValidated', isValidated);

        if (isValid && this.$v.airpoints.$dirty) {
          this.onNextSection();
        }

        return isValid;
      }
    },
    promoCode: {
      isValid() {
        return this.$store.state.offer.promoCodeValid;
      }
    },
    membershipNumber: {
      required: requiredIf(nestedModel => nestedModel.membershipNumberRequired),
      maxLength: maxLength(20)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
.no-discount-off-pump {
  padding-bottom: 50px;
}
</style>
